import ReactSlider from "react-slider";
import './Slider.css';

const Slider = ({min, max, defVal, onChange}) => {
  return (
    <ReactSlider
        className="horizontal-slider"
        marks
        markClassName="example-mark"
        min={min}
        max={max}
        defaultValue={defVal}
        onChange={onChange}
        thumbClassName="example-thumb"
        trackClassName="example-track"
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
    />
  );
};
export default Slider;