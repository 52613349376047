import background from './screencapture-rainbow-moonbeam-829004-netlify-app-2023-02-06-21_33_09.png';
import Counter from './Counter'
import './App.css';

function App() {
  return (
    <div className="App" style= {{ 
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'rgb(17, 17, 17)',
      height: '3000px' 
      }}>
        <div className="container">
            <div className="left">
            </div>
            <div className="middle">
              <Counter></Counter>
            </div>
            <div className="right">
            </div>
        </div>
    </div>
  );
}

export default App;
