
import { useState, useEffect, /* localStorage */ } from 'react';
import Slider from './Slider'

function Counter() {
    
    const [bedTime, setBedTime] = useState({hours: 23, minutes: 0});
    const [wakeupTime, setWakeupTime] = useState({ hours: 0, minutes: 0 });

    const [timerCounter, setTimerCounter] = useState(calculateDifference(bedTime, wakeupTime));

    // const [useLocalTime, setUseLocalTime] = useState(false);
    
    function timeToString(timeObject) {
        let string = "";
        string = formatDigits(timeObject.hours ? timeObject.hours : 0) + 
            " : " + formatDigits(timeObject.minutes ? timeObject.minutes : 0) + 
            " : " + formatDigits(timeObject.seconds ? timeObject.seconds : 0);

        return string;
    }

    function formatDigits(val) {
        return val.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
    }

    function counterRender() {
        if (timerCounter.bedtime) {
            return "It's bedtime";
        }
        return timeToString(timerCounter);
    }
    
    
    useEffect(() => {
        function refreshCounter() {
            setTimerCounter(calculateDifference());
        }
        const timerId = setInterval((refreshCounter), 100);
        return function cleanup() {
          clearInterval(timerId);
        };
      }, [bedTime, wakeupTime, calculateDifference]);
    
    function calculateDifference() {
        let date = new Date();
        let timeZoneOffset = date.getTimezoneOffset();
        let time_now = (date % (1000*60*60*24)) - (timeZoneOffset*60*1000);
        // If not time to wake up yet = bedtime
        if (time_now < (wakeupTime.hours*1000*60*60+wakeupTime.minutes*1000*60)) {
            return {bedtime: true, days:0, hours:0, minutes:0, seconds: 0};
        // If already past bedtime  = bedtime
        } else if (time_now > (bedTime.hours*1000*60*60+bedTime.minutes*1000*60)) {
            return {bedtime: true, days:0, hours:0, minutes:0, seconds: 0};
        } else {
            let difference = (bedTime.hours*1000*60*60)+(bedTime.minutes*1000*60)-time_now;
            let hours = Math.floor(difference / (1000*3600));
            difference -= hours * (1000*3600);
            let minutes = Math.floor(difference / (1000*60));
            difference -= minutes * (1000*60);
            let seconds = Math.floor(difference / (1000));
            difference -= seconds * (1000);
            return { bedtime: false, hours:hours, minutes: minutes, seconds: seconds };
        }
    }
    
    return (
        <div>
            <p> { counterRender() }</p>
            <div>
                <div><p>Bed time: { timeToString(bedTime) }</p></div>
                <Slider 
                    min={0} max={23} defVal={bedTime.hours} 
                    onChange={(newVal) => setBedTime({hours: newVal, minutes: bedTime.minutes})}>
                </Slider>
                <Slider min={0} max={59} defVal={bedTime.minutes} 
                    onChange={(newVal) => setBedTime({hours: bedTime.hours, minutes: newVal}) }>
                </Slider>
            </div>
            <div>
                <div><p>Wake up time: { timeToString(wakeupTime) }</p></div>
                
                <Slider min={0} max={23} defVal={wakeupTime.hours} 
                    onChange={(newVal) => setWakeupTime({hours: newVal, minutes: wakeupTime.minutes}) }>
                </Slider>
                <Slider min={0} max={59} defVal={wakeupTime.minutes} 
                    onChange={(newVal) => setWakeupTime({hours: wakeupTime.hours, minutes: newVal}) }>
                </Slider>
            </div>

        </div>
    );
}  



export default Counter;